<template>
  <perfect-scrollbar
    class="layoutDesign-desktop"
    ref="layoutDesign-desktop"
    @ps-scroll-y="onScroll"
  >
    <b-modal
      id="showcase-modal"
      hide-footer
      hide-header
      size="lg"
      @hide="closeModal"
    >
      <div class="close" @click="closeModal"></div>
      <div class="title">{{ $t("layoutDesign.desktop.showcaseItems") }}</div>
      <div class="desc">{{ $t("layoutDesign.desktop.choose") }}</div>
      <div class="list-controller">
        <div class="items-selected">
          {{
            $t("layoutDesign.desktop.selected", {
              var1: showcaseItemsTemp.length,
            })
          }}
        </div>
        <div class="controller">
          <div
            :class="[
              'select',
              {
                inactive: showcaseItemsTemp.length == showcaseItemsList.length,
              },
            ]"
            @click="selectAll"
          >
            {{ $t("layoutDesign.desktop.selectAll") }}
          </div>
          <div
            :class="['clear', { inactive: showcaseItemsTemp.length == 0 }]"
            @click="clearAll"
          >
            {{ $t("layoutDesign.desktop.clearAll") }}
          </div>
        </div>
      </div>
      <perfect-scrollbar class="items-list">
        <draggable
          v-model="orderedShowcaseItemsList"
          v-bind="$options.DRAG_OPTIONS"
          handle=".drag"
        >
          <div
            class="list-item"
            v-for="item in orderedShowcaseItemsList"
            :key="item.value + '_showcase'"
          >
            <div class="info-container">
              <div class="drag"></div>
              <b-checkbox
                :checked="
                  showcaseItemsTemp.filter(
                    (showcaseItem) => showcaseItem.value == item.value
                  ).length > 0
                "
                @change="() => setShowcaseItem(item)"
              >
                {{ item.text }}
              </b-checkbox>
              <span v-if="item.value.startsWith('dynamic')">
                <span
                  class="icon"
                  @mouseenter="$bvToast.show('id-toast-' + item.value)"
                >
                </span>
              </span>
              <div
                class="add"
                @click="addDynamicBanner"
                v-if="item.value === 'dynamic-1'"
              ></div>
              <div
                class="remove"
                @click="() => removeDynamicBanner(item.value.split('-')[1])"
                v-else-if="item.value.startsWith('dynamic')"
              ></div>
              <b-toast
                v-if="item.value.startsWith('dynamic')"
                :id="'id-toast-' + item.value"
                variant="info"
                static
                no-close-button
                solid
                :auto-hide-delay="1000"
              >
                {{ $t("banners.enableDynamicBlock") }} <br />
                {{
                  $t("layoutDesign.desktop.desktopId") +
                    item.value.split("-")[1]
                }}
              </b-toast>
            </div>
          </div>
        </draggable>
      </perfect-scrollbar>
      <div class="footer">
        <div class="btn" @click="save">
          {{ $t("layoutDesign.desktop.confirm") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="main-categories-modal"
      hide-footer
      hide-header
      size="lg"
      @hide="closeCategoriesModal"
    >
      <div class="close" @click="closeCategoriesModal"></div>
      <div class="title">
        {{ $t("layoutDesign.desktop.mainCategories") }}
      </div>
      <div class="desc">
        {{ $t("layoutDesign.desktop.mainCategoriesText") }}
      </div>
      <div class="list-controller">
        <div class="items-selected">
          {{
            $t("layoutDesign.desktop.selected", {
              var1: mainCategoriesTemp.length,
            })
          }}
        </div>
        <div class="controller">
          <div
            :class="['clear', { inactive: mainCategoriesTemp.length == 0 }]"
            @click="clearAllCategories"
          >
            {{ $t("layoutDesign.desktop.clearAll") }}
          </div>
        </div>
      </div>
      <perfect-scrollbar class="items-list">
        <div
          class="list-item"
          v-for="(item, index) in mainCategories"
          :key="'cat_' + index"
        >
          <div class="info-container" v-if="!item.children">
            <b-checkbox
              :checked="
                mainCategoriesTemp.filter(
                  (mainCategoriesItem) =>
                    mainCategoriesItem.key == item.identifier
                ).length > 0
              "
              @change="() => setMainCategoriesItem(item)"
            >
              {{ item.name }}
            </b-checkbox>
          </div>
          <div
            :class="[
              'collapse-container',
              { expanded: categoriesExpanded['cat_' + index] },
            ]"
            v-if="item.children && item.children.length > 0"
          >
            <div
              class="header-collapse"
              @click="() => expandAccordion('cat', index)"
            >
              <b-checkbox
                :checked="
                  mainCategoriesTemp.filter(
                    (mainCategoriesItem) =>
                      mainCategoriesItem.key == item.identifier
                  ).length > 0
                "
                @change="() => setMainCategoriesItem(item)"
              >
                {{ item.name }}
              </b-checkbox>
              <div class="icon"></div>
            </div>
            <b-collapse
              accordion="accordion"
              v-model="categoriesExpanded['cat_' + index]"
            >
              <div class="accordion-content">
                <div
                  class="row"
                  v-for="(child, id) in item.children"
                  :key="'cat_' + id"
                >
                  <b-checkbox
                    :checked="
                      mainCategoriesTemp.filter(
                        (mainCategoriesItem) =>
                          mainCategoriesItem.key == child.identifier
                      ).length > 0
                    "
                    @change="() => setMainCategoriesItem(child)"
                  >
                    {{ child.name }}
                  </b-checkbox>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </perfect-scrollbar>
      <div class="footer">
        <div class="btn" @click="addCategory">
          {{ $t("layoutDesign.desktop.confirm") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="recommendations-modal"
      hide-footer
      hide-header
      size="lg"
      @hide="closeRecomendationsModal"
    >
      <div class="close" @click="closeRecomendationsModal"></div>
      <div class="title">
        {{ $t("layoutDesign.desktop.recommendationCategories") }}
      </div>
      <div class="desc">
        {{ $t("layoutDesign.desktop.recommendationsText") }}
      </div>
      <div class="list-controller">
        <div class="items-selected">
          {{
            $t("layoutDesign.desktop.selected", {
              var1: recommendationsTemp.length,
            })
          }}
        </div>
        <div class="controller">
          <div
            :class="['clear', { inactive: recommendationsTemp.length == 0 }]"
            @click="clearAllRecommendations"
          >
            {{ $t("layoutDesign.desktop.clearAll") }}
          </div>
        </div>
      </div>
      <perfect-scrollbar class="items-list">
        <div
          class="list-item"
          v-for="(item, index) in mainCategories"
          :key="'rec_' + index"
        >
          <div class="info-container" v-if="!item.children">
            <b-checkbox
              :checked="
                recommendationsTemp.filter(
                  (recommendationsItem) =>
                    recommendationsItem.key == item.identifier
                ).length > 0
              "
              @change="() => setRecommendationsItem(item)"
            >
              {{ item.name }}
            </b-checkbox>
          </div>
          <div
            :class="[
              'collapse-container',
              { expanded: recommendationsExpanded['rec_' + index] },
            ]"
            v-if="item.children && item.children.length > 0"
          >
            <div
              class="header-collapse"
              @click="() => expandAccordion('rec', index)"
            >
              <b-checkbox
                :checked="
                  recommendationsTemp.filter(
                    (recommendationsItem) =>
                      recommendationsItem.key == item.identifier
                  ).length > 0
                "
                @change="() => setRecommendationsItem(item)"
              >
                {{ item.name }}
              </b-checkbox>
              <div class="icon"></div>
            </div>
            <b-collapse
              accordion="accordion"
              v-model="recommendationsExpanded['rec_' + index]"
            >
              <div class="accordion-content">
                <div
                  class="row"
                  v-for="(child, id) in item.children"
                  :key="'rec_' + id"
                >
                  <b-checkbox
                    :checked="
                      recommendationsTemp.filter(
                        (recommendationsItem) =>
                          recommendationsItem.key == child.identifier
                      ).length > 0
                    "
                    @change="() => setRecommendationsItem(child)"
                  >
                    {{ child.name }}
                  </b-checkbox>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </perfect-scrollbar>
      <div class="footer">
        <div class="btn" @click="addRecommendationCategory">
          {{ $t("layoutDesign.desktop.confirm") }}
        </div>
      </div>
    </b-modal>
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.desktop.layerLayout") }}
        </div>
        <div class="desc">{{ $t("layoutDesign.desktop.chooseBehave") }}</div>
        <select v-model="layout" class="custom-select">
          <option
            v-for="option in layoutOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <div class="sub-desc">{{ $t("layoutDesign.desktop.thisMode") }}</div>
        <div
          class="label"
          v-if="
            this.getCurrentConfiguration.layout.desktop.layout == 'full-screen'
          "
        >
          {{ $t("layoutDesign.desktop.logoUrl") }}
        </div>
        <div
          class="img-input-container"
          :id="'img_logo_url'"
          v-if="
            this.getCurrentConfiguration.layout.desktop.layout == 'full-screen'
          "
        >
          <div class="img-placeholder"></div>
          <b-form-input v-model="logoUrl" />
        </div>
        <b-popover
          :target="'img_logo_url'"
          triggers="hover"
          placement="top"
          v-if="
            this.getCurrentConfiguration.layout.desktop.layout == 'full-screen'
          "
        >
          <img :src="logoUrl" :alt="logoUrl" @error="replaceByDefault" />
        </b-popover>
      </div>
      <div :class="['img-column layout', layout]" ref="layerLayout-row">
        <div :class="['img', scrollCheck($refs['layerLayout-row'])]"></div>
      </div>
    </div>
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title">{{ $t("layoutDesign.desktop.typing") }}</div>
        <div class="desc">{{ $t("layoutDesign.desktop.chooseDisplay") }}</div>
        <!-- <b-form-select v-model="getCurrentConfiguration.layout.desktop.typing" :options="$options.typingOptions"/> -->
        <b-select
          v-model="typing"
          class="custom-select"
          v-if="layout == 'windowed'"
        >
          <option
            v-for="option in typingOptionsWindowed"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
        <b-select v-model="typing" class="custom-select" v-else>
          <option
            v-for="option in typingOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
        <div class="sub-desc">
          {{ $t("layoutDesign.desktop.instantResults") }}
        </div>
        <div class="label">{{ $t("layoutDesign.desktop.minimum") }}</div>
        <!-- <b-form-input type="number" min="1" max="10" v-model="getCurrentConfiguration.layout.desktop.minChars" /> -->
        <b-form-input type="number" min="3" max="10" v-model="minChars" />
      </div>
      <!-- <div class="img-column typing" ref="typing-row"> <div :class="['img',scrollCheck($refs['typing-row'])]"></div> </div> -->
    </div>
    <div
      class="sub-title danger"
      v-if="
        showcaseDisabled &&
          $options.isStartOrMore(Object.values(getSelectedPlan)[0].key)
      "
      v-html="$t('layoutDesign.desktop.activateShowcase')"
    ></div>
    <div
      class="available"
      v-else-if="!$options.isStartOrMore(Object.values(getSelectedPlan)[0].key)"
    >
      <span v-html="$t('layoutDesign.available')"> </span>
      <span class="icon"> </span>
    </div>
    <div :class="['function-row', { disabled: showcaseDisabled }]">
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.desktop.showcaseFeature") }}
        </div>
        <div class="desc">{{ $t("layoutDesign.desktop.displayTrending") }}</div>
        <div class="add-row">
          <div
            class="label"
            v-html="$t('layoutDesign.desktop.showcaseItemsAppear')"
          ></div>
          <div class="add" @click="openModal">
            {{
              showcaseItems.length > 0
                ? $t("layoutDesign.desktop.manageItems")
                : $t("layoutDesign.desktop.addItems")
            }}
          </div>
        </div>
      </div>
      <div class="img-column showcase"><div class="img"></div></div>
    </div>
    <div
      :class="['function-row', 'showcase-item', { disabled: showcaseDisabled }]"
      v-if="showcaseItems.some((item) => item.value == 'mainCategories')"
    >
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.desktop.showcaseMainCategories") }}
        </div>
        <div class="desc" v-html="$t('layoutDesign.desktop.thisElement')"></div>
        <div class="toggle-row">
          <div class="toggle-label">
            {{ $t("layoutDesign.desktop.enableDisable") }}
          </div>
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            v-model="showcaseMainCategories"
            :sync="true"
          />
        </div>
        <div class="items-list">
          <draggable v-model="showcaseMainCategoriesItems">
            <div
              class="drag-item"
              v-for="(item, index) in showcaseMainCategoriesItems"
              :key="'img_' + item.value + index"
            >
              <!-- <div
                class="delete"
                v-if="!item.isDefault"
                @click="() => deleteCategory(index)"
              ></div> -->
              <div class="icon"></div>
              <div class="category">
                {{
                  item.value
                    .split("/")
                    .at(-1)
                    .replace("*", "")
                }}
              </div>
              <b-input
                class="sort-label smaller"
                :value="item.name"
                @input="(value) => (categoryLabel = value)"
                @focus="categoryLabel = item.name"
                @blur="
                  () => {
                    onCategoryInput('name', categoryLabel, index)
                  }
                "
                placeholder="label"
              >
              </b-input>
              <!-- <b-input class="sort-label" :value="item.key" @input="value=>categoryLabel=value" @blur="()=>onCategoryInput('key',categoryLabel,index)" placeholder="key"> </b-input> -->
              <div
                class="img-input-container smaller"
                :id="'img_' + item.value + index"
              >
                <div class="img-placeholder"></div>
                <b-input
                  class="sort-label"
                  :value="item.img"
                  @input="(value) => (categoryLabel = value)"
                  @focus="categoryLabel = item.img"
                  @blur="() => onCategoryInput('img', categoryLabel, index)"
                  placeholder="url"
                >
                </b-input>
              </div>
              <b-popover
                :target="'img_' + item.value + index"
                triggers="hover"
                placement="top"
              >
                <img
                  :src="item.img"
                  :alt="item.value"
                  @error="replaceByDefault"
                />
              </b-popover>
            </div>
          </draggable>
          <div class="add-row">
            <div class="add" @click="openCategoriesModal">
              {{ $t("layoutDesign.desktop.addCategory") }}
            </div>
          </div>
        </div>
      </div>
      <div class="img-column mainCategories" ref="mainCategories-row">
        <div :class="['img', scrollCheck($refs['mainCategories-row'])]"></div>
      </div>
    </div>
    <div
      :class="[
        'function-row',
        'showcase-item',
        'recommendations-categories',
        { disabled: showcaseDisabled },
      ]"
      v-if="
        showcaseItems.some((item) => item.value == 'recommendationsCategories')
      "
    >
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.desktop.showcaseRecommendationCategories") }}
        </div>
        <div class="desc">
          {{ $t("layoutDesign.desktop.trendingCategories") }}
        </div>
        <div class="toggle-row">
          <div class="toggle-label">
            {{ $t("layoutDesign.desktop.enableDisable") }}
          </div>
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            v-model="showcaseRecommendationsCategories"
            :sync="true"
          />
        </div>
        <div class="items-list">
          <draggable v-model="recommendationCategoriesItems">
            <div
              class="drag-item"
              v-for="(item, index) in recommendationCategoriesItems"
              :key="item.value"
            >
              <div class="icon"></div>
              <div class="category">
                {{
                  item.value
                    .split("/")
                    .at(-1)
                    .replace("*", "")
                }}
              </div>
              <!-- <div
                class="delete"
                v-if="!item.isDefault"
                @click="() => deleteRecommendationCategory(index)"
              ></div> -->
              <b-input
                class="sort-label"
                :value="item.name"
                @input="(value) => (categoryLabel = value)"
                @focus="categoryLabel = item.name"
                @blur="
                  () => {
                    onRecommendationCategoryInput('name', categoryLabel, index)
                  }
                "
                placeholder="label"
              >
              </b-input>
            </div>
          </draggable>
          <div class="add-row">
            <div class="add" @click="openRecommendationsModal">
              {{ $t("layoutDesign.desktop.addCategory") }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="img-column recommendationsCategories"
        ref="recommendationsCategories-row"
      >
        <div
          :class="['img', scrollCheck($refs['recommendationsCategories-row'])]"
        ></div>
      </div>
    </div>
    <div
      :class="['function-row', 'showcase-item', { disabled: showcaseDisabled }]"
      v-if="showcaseItems.some((item) => item.value == 'lastSearches')"
    >
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.desktop.showcaseLastSearches") }}
        </div>
        <div class="desc">
          {{ $t("layoutDesign.desktop.lastSearchesDesc") }}
        </div>
        <div class="toggle-row">
          <div class="toggle-label">
            {{ $t("layoutDesign.desktop.enableDisable") }}
          </div>
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            v-model="showcaseLastSearches"
            :sync="true"
          />
        </div>
        <div class="label">{{ $t("layoutDesign.desktop.historyReset") }}</div>
        <b-select v-model="showcaseLastSearchesOption" class="custom-select">
          <option
            v-for="option in showcaseLastSearchesOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </b-select>
      </div>
      <div class="img-column lastSearches" ref="lastSearches-row">
        <div :class="['img', scrollCheck($refs['lastSearches-row'])]"></div>
      </div>
    </div>
    <div
      :class="['function-row', 'showcase-item', { disabled: showcaseDisabled }]"
      v-if="showcaseItems.some((item) => item.value == 'banner')"
    >
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.desktop.showcasePromotionalBanner") }}
        </div>
        <div class="desc">{{ $t("layoutDesign.desktop.displayAd") }}</div>
        <div class="toggle-row">
          <div class="toggle-label">
            {{ $t("layoutDesign.desktop.enableDisable") }}
          </div>
          <toggle-button
            class="toggle-button"
            color="#398ff4"
            v-model="showcaseBanner"
            :sync="true"
          />
        </div>
        <div class="sub-desc">{{ $t("layoutDesign.desktop.recommend") }}</div>
        <div class="label">*{{ $t("layoutDesign.desktop.bannerName") }}</div>
        <div class="name-div">
          <b-tooltip target="banner-name-id" triggers="click">
            {{ $t("layoutDesign.desktop.bannerTooltip") }}
          </b-tooltip>
          <b-input
            class="sort-label"
            :value="showcaseBannerName"
            @input="(value) => (categoryLabel = value)"
            @focus="categoryLabel = showcaseBannerName"
            @blur="
              () => {
                showcaseBannerName = categoryLabel
              }
            "
            :placeholder="$t('layoutDesign.desktop.bannerNameValue')"
          >
          </b-input>
          <div class="icon-wrapper" id="banner-name-id"></div>
        </div>
        <div class="label">*{{ $t("layoutDesign.desktop.bannerImage") }}</div>
        <div class="img-input-container" :id="'img_banner'">
          <div class="img-placeholder"></div>
          <b-input
            class="sort-label"
            :value="showcaseBannerSrc"
            @input="(value) => (categoryLabel = value)"
            @focus="categoryLabel = showcaseBannerSrc"
            @blur="
              () => {
                showcaseBannerSrc = categoryLabel
              }
            "
            :placeholder="$t('layoutDesign.desktop.imageUrl')"
          >
          </b-input>
        </div>
        <b-popover :target="'img_banner'" triggers="hover" placement="top">
          <img
            :src="showcaseBannerSrc"
            :alt="showcaseBannerSrc"
            @error="replaceByDefault"
          />
        </b-popover>
        <a
          href="/img/1440x160 sample.png"
          download="banner-sample.png"
          class="label download"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div class="icon"></div>
          {{ $t("layoutDesign.desktop.sampleDownload") }}
        </a>
        <div class="label">*{{ $t("layoutDesign.desktop.where") }}</div>
        <b-input
          class="sort-label"
          :value="showcaseBannerUrl"
          @input="(value) => (categoryLabel = value)"
          @focus="categoryLabel = showcaseBannerUrl"
          @blur="
            () => {
              showcaseBannerUrl = categoryLabel
            }
          "
          :placeholder="$t('layoutDesign.desktop.websiteUrl')"
        >
        </b-input>
      </div>
      <div class="img-column banner" ref="banner-row">
        <div :class="['img', scrollCheck($refs['banner-row'])]"></div>
      </div>
    </div>
    <div class="function-row">
      <div class="info-column">
        <div class="sub-title">
          {{ $t("layoutDesign.desktop.productListing") }}
        </div>
        <div class="desc">{{ $t("layoutDesign.desktop.chooseProducts") }}</div>
        <div class="selects-row">
          <div class="select-container">
            <div class="label">
              {{ $t("layoutDesign.desktop.pagingDisplay") }}
            </div>
            <select v-model="paging" class="custom-select">
              <option
                v-for="option in pagingOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="select-container">
            <div class="label">
              {{ $t("layoutDesign.desktop.productsPerPage") }}
            </div>
            <select v-model="perPage" class="custom-select">
              <option
                v-for="option in perPageOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="img-column listing"><div class="img"></div></div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import lodash from "lodash"
import Utils from "@/utils/Utils.js"
import axios from "@/utils/Axios"

export default {
  name: "LayoutDesignDesktop",
  isStartOrMore: Utils.isStartOrMore,
  DRAG_OPTIONS: Utils.DRAG_OPTIONS,
  data() {
    return {
      showcaseItemsTemp: [],
      categoryLabel: "",
      scrollTop: 0,
      layoutOptions: [
        {
          value: "full-screen",
          text: this.$t("layoutDesign.desktop.fullScreen"),
        },
        {
          value: "half-screen",
          text: this.$t("layoutDesign.desktop.halfScreen"),
        },
        { value: "windowed", text: this.$t("layoutDesign.desktop.windowed") },
      ],
      typingOptions: [
        {
          value: "input",
          text: this.$t("layoutDesign.desktop.searchInstantResults"),
        },
        { value: "click", text: this.$t("layoutDesign.desktop.showListing") },
      ],
      typingOptionsWindowed: [
        {
          value: "input",
          text: this.$t("layoutDesign.desktop.searchInstantResults"),
        },
        // {value: 'click', text: 'Show listing on click', disabled:true}
      ],
      pagingOptions: [
        {
          value: "infinite-scrolling",
          text: this.$t("layoutDesign.desktop.infiniteScroll"),
        },
        { value: "paginator", text: this.$t("layoutDesign.desktop.paginator") },
      ],
      perPageOptions: [
        { value: 6, text: "6" },
        { value: 12, text: "12" },
        { value: 18, text: "18" },
        { value: 24, text: "24" },
      ],
      showcaseLastSearchesOptions: [
        { value: "1D", text: this.$t("layoutDesign.desktop.tomorrow") },
        { value: "1W", text: this.$t("layoutDesign.desktop.nextWeek") },
        { value: "1Y", text: this.$t("layoutDesign.desktop.nextYear") },
      ],
      showcaseItemsList: [
        // {text:'Recommendations (Products)',value:'recommendationsProducts'},
        {
          text: this.$t("layoutDesign.desktop.promotionalBanner"),
          value: "banner",
        },
        {
          text: this.$t("layoutDesign.desktop.lastSearches"),
          value: "lastSearches",
        },
        {
          text: this.$t("layoutDesign.desktop.recommendationCategories"),
          value: "recommendationsCategories",
        },
        {
          text: this.$t("layoutDesign.desktop.mainCategories"),
          value: "mainCategories",
        },
        // {text:'Hub of Links',value:'links'},
        { text: this.$t("layoutDesign.desktop.dynamic"), value: "dynamic-1" },
      ],
      orderedShowcaseItemsList: [],
      mainCategories: [],
      mainCategoriesTemp: [],
      categoriesExpanded: {},
      recommendationsTemp: [],
      recommendationsExpanded: {},
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentConfiguration",
      "getHistoryChanges",
      "getSelectedPlan",
    ]),
    minChars: {
      set(value) {
        if (value > 0 && value < 11) {
          this.setValue("minChars", value)
        }
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.minChars
      },
    },
    typing: {
      set(value) {
        this.setValue("typing", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.typing
      },
    },
    paging: {
      set(value) {
        this.setValue("paging", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.paging
      },
    },
    perPage: {
      set(value) {
        this.setValue("perPage", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.perPage
      },
    },
    logoUrl: {
      set(value) {
        this.setValue("logoUrl", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.logoUrl
      },
    },
    layout: {
      set(value) {
        if (value == "windowed") this.setValue("typing", "input")
        else this.setValue("typing", "click")
        this.setValue("layout", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.layout
      },
    },
    showcase: {
      set(value) {
        this.setValue("showcase", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.showcase
      },
    },
    showcaseLastSearches: {
      set(value) {
        this.setValue("showcaseLastSearches", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.showcaseLastSearches
      },
    },
    showcaseBanner: {
      set(value) {
        this.setValue("showcaseBanner", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.showcaseBanner
      },
    },
    showcaseBannerSrc: {
      set(value) {
        this.setValue("showcaseBannerSrc", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.showcaseBannerSrc
          ? this.getCurrentConfiguration.layout.desktop.showcaseBannerSrc
          : ""
      },
    },
    showcaseBannerUrl: {
      set(value) {
        this.setValue("showcaseBannerUrl", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.showcaseBannerUrl
          ? this.getCurrentConfiguration.layout.desktop.showcaseBannerUrl
          : ""
      },
    },
    showcaseBannerName: {
      set(value) {
        this.setValue("showcaseBannerName", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.showcaseBannerName
          ? this.getCurrentConfiguration.layout.desktop.showcaseBannerName
          : ""
      },
    },
    showcaseMainCategories: {
      set(value) {
        this.setValue("showcaseMainCategories", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop
          .showcaseMainCategories
      },
    },
    showcaseMainCategoriesItems: {
      set(value) {
        this.setValue("showcaseMainCategoriesItems", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop
          .showcaseMainCategoriesItems
          ? this.getCurrentConfiguration.layout.desktop
              .showcaseMainCategoriesItems
          : []
      },
    },
    showcaseRecommendationsCategories: {
      set(value) {
        this.setValue("showcaseRecommendationsCategories", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop
          .showcaseRecommendationsCategories
      },
    },
    recommendationCategoriesItems: {
      set(value) {
        this.setValue("recommendationCategoriesItems", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop
          .recommendationCategoriesItems
          ? this.getCurrentConfiguration.layout.desktop
              .recommendationCategoriesItems
          : []
      },
    },
    showcaseLastSearchesOption: {
      set(value) {
        this.setValue("showcaseLastSearchesOption", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop
          .showcaseLastSearchesOption
      },
    },
    showcaseItems: {
      set(value) {
        this.setValue("showcaseItems", value)
      },
      get() {
        return this.getCurrentConfiguration.layout.desktop.showcaseItems
          ? this.getCurrentConfiguration.layout.desktop.showcaseItems
          : []
      },
    },
    showcaseDisabled() {
      return (
        this.typing == this.typingOptions[0].value ||
        this.layout == this.layoutOptions[2].value ||
        !Utils.isStartOrMore(Object.values(this.getSelectedPlan)[0].key)
      )
    },
  },
  methods: {
    ...mapActions(["setCurrentConfigurationByField", "setHistoryChanges"]),
    pushHistoryChanges() {
      var historyChanges = lodash.cloneDeep(this.getHistoryChanges)
      historyChanges.push(lodash.cloneDeep(this.getCurrentConfiguration))
      this.setHistoryChanges(historyChanges)
    },
    onScroll() {},
    scrollCheck(img) {
      if (this.scrollTop < img?.offsetTop - 10) return ""
      if (
        this.scrollTop >
        img?.offsetTop +
          img?.offsetHeight -
          img?.childNodes[0].offsetHeight -
          10
      )
        return "scrolled"
      return "fixed"
    },
    setValue(key, value) {
      this.pushHistoryChanges()
      var currentConfiguration = lodash.cloneDeep(
        this["getCurrentConfiguration"]
      )
      currentConfiguration.layout.desktop[key] = value
      this.setCurrentConfigurationByField({
        field: "layout",
        value: currentConfiguration.layout,
      })
    },
    expandAccordion(type, index) {
      if (type === "cat") {
        let valueToChange = this.categoriesExpanded["cat_" + index]
        this.categoriesExpanded = {}
        // eslint-disable-next-line no-unused-vars
        this.mainCategories.forEach(
          (value, index) => (this.categoriesExpanded["cat_" + index] = false)
        )
        this.categoriesExpanded["cat_" + index] = !valueToChange
      } else {
        let valueToChange = this.recommendationsExpanded["rec_" + index]
        this.recommendationsExpanded = {}
        // eslint-disable-next-line no-unused-vars
        this.mainCategories.forEach(
          (value, index) =>
            (this.recommendationsExpanded["rec_" + index] = false)
        )
        this.recommendationsExpanded["rec_" + index] = !valueToChange
      }
    },
    selectAll() {
      this.showcaseItemsTemp = lodash.cloneDeep(this.orderedShowcaseItemsList)
    },
    clearAll() {
      this.showcaseItemsTemp = []
    },
    selectAllCategories() {
      this.mainCategoriesTemp = lodash.cloneDeep(this.mainCategories)
    },
    clearAllCategories() {
      this.mainCategoriesTemp = []
    },
    clearAllRecommendations() {
      this.recommendationsTemp = []
    },

    setShowcaseItem(item) {
      var itemIndex = this.showcaseItemsTemp.findIndex(
        (tempItem) => tempItem.value == item.value
      )
      if (itemIndex > -1) this.showcaseItemsTemp.splice(itemIndex, 1)
      else this.showcaseItemsTemp.push(item)
    },
    setMainCategoriesItem(item) {
      var itemIndex = this.mainCategoriesTemp.findIndex((tempItem) => {
        return tempItem.key == item.identifier
      })
      if (itemIndex > -1) {
        this.mainCategoriesTemp.splice(itemIndex, 1)
      } else {
        this.mainCategoriesTemp.push({ ...item, key: item.identifier })
      }
    },
    setRecommendationsItem(item) {
      var itemIndex = this.recommendationsTemp.findIndex((tempItem) => {
        return tempItem.key == item.identifier
      })
      if (itemIndex > -1) {
        this.recommendationsTemp.splice(itemIndex, 1)
      } else {
        this.recommendationsTemp.push({ ...item, key: item.identifier })
      }
    },
    openModal() {
      this.showcaseItemsTemp = lodash.cloneDeep(this.showcaseItems)
      this.$bvModal.show("showcase-modal")
    },
    closeModal() {
      this.resetShowcaseItemList()
      this.$bvModal.hide("showcase-modal")
    },
    openCategoriesModal() {
      this.mainCategoriesTemp = lodash.cloneDeep(
        this.showcaseMainCategoriesItems
      )
      this.$bvModal.show("main-categories-modal")
    },
    closeCategoriesModal() {
      this.$bvModal.hide("main-categories-modal")
    },
    openRecommendationsModal() {
      this.recommendationsTemp = lodash.cloneDeep(
        this.recommendationCategoriesItems
      )
      this.$bvModal.show("recommendations-modal")
    },
    closeRecomendationsModal() {
      this.$bvModal.hide("recommendations-modal")
    },
    save() {
      this.showcaseItems = lodash.cloneDeep(
        this.orderedShowcaseItemsList.filter((item) =>
          this.showcaseItemsTemp
            .map((subItem) => subItem.value)
            .includes(item.value)
        )
      )
      this.$bvModal.hide("showcase-modal")
    },

    addCategory() {
      let arrayToReturn = []

      this.mainCategoriesTemp.forEach((item) => {
        let path = item.name
        let parentTemp = item.parent
        if (item.value) {
          path = item.value
        } else {
          while (parentTemp) {
            path = parentTemp.name + "/" + path
            parentTemp = parentTemp.parent
          }
        }
        arrayToReturn.push({
          key: item.identifier ?? item.key,
          value: path,
          img: "",
          name: item.name,
        })
      })
      this.showcaseMainCategoriesItems = lodash.cloneDeep(
        arrayToReturn.slice(0, 12)
      )
      this.closeCategoriesModal()
    },
    deleteCategory(index) {
      var arrayToReturn = lodash.cloneDeep(this.showcaseMainCategoriesItems)
      arrayToReturn.splice(index, 1)
      this.showcaseMainCategoriesItems = lodash.cloneDeep(arrayToReturn)
    },
    onCategoryInput(type, value, index) {
      var arrayToReturn = lodash.cloneDeep(this.showcaseMainCategoriesItems)
      arrayToReturn[index][type] = value
      this.showcaseMainCategoriesItems = lodash.cloneDeep(arrayToReturn)
    },
    deleteRecommendationCategory(index) {
      var arrayToReturn = lodash.cloneDeep(this.recommendationCategoriesItems)
      arrayToReturn.splice(index, 1)
      this.recommendationCategoriesItems = lodash.cloneDeep(arrayToReturn)
    },
    addRecommendationCategory() {
      var arrayToReturn = []
      this.recommendationsTemp.forEach((item) => {
        let path = item.name
        let parentTemp = item.parent
        if (item.value) {
          path = item.value
        } else {
          while (parentTemp) {
            path = parentTemp.name + "/" + path
            parentTemp = parentTemp.parent
          }
        }
        arrayToReturn.push({
          key: item.identifier ?? item.key,
          value: path,
          name: item.name,
        })
      })
      this.recommendationCategoriesItems = lodash.cloneDeep(
        arrayToReturn.slice(0, 8)
      )
      this.closeRecomendationsModal()
    },
    onRecommendationCategoryInput(type, value, index) {
      var arrayToReturn = lodash.cloneDeep(this.recommendationCategoriesItems)
      arrayToReturn[index][type] = value
      this.recommendationCategoriesItems = lodash.cloneDeep(arrayToReturn)
    },
    replaceByDefault(e) {
      e.target.src = require("@/assets/icons/img-not found.svg")
    },
    resetShowcaseItemList() {
      this.orderedShowcaseItemsList = lodash.cloneDeep(this.showcaseItemsList)
      this.orderedShowcaseItemsList = [
        ...this.orderedShowcaseItemsList,
        ...this.showcaseItems.filter(
          (item) =>
            item.value.startsWith("dynamic") && item.value != "dynamic-1"
        ),
      ]
      this.orderedShowcaseItemsList.sort((a, b) => {
        var values = this.showcaseItems.map((item) => item.value)
        var valueA = values.indexOf(a.value)
        var valueB = values.indexOf(b.value)
        valueA = valueA == -1 ? 100 : valueA
        valueB = valueB == -1 ? 100 : valueB
        return valueA - valueB
      })
    },
    addDynamicBanner() {
      var dynamicsIndexs = this.orderedShowcaseItemsList
        .filter((item) => item.value.startsWith("dynamic"))
        .map((item) => parseInt(item.value.split("-")[1]))
      const max = dynamicsIndexs.reduce((a, b) => {
        return Math.max(a, b)
      })
      const indexToUse = max + 1
      this.orderedShowcaseItemsList.push({
        text: this.$t("layoutDesign.desktop.dynamic") + " " + indexToUse,
        value: "dynamic-" + indexToUse,
      })
    },
    reverseCategoryPath(cat) {
      if (cat.parent === null) {
        return cat
      } else {
        let parent = this.reverseCategoryPath(cat.parent)
        parent.children = [...(parent.children ?? []), cat]
        return parent
      }
    },
    changeCategoriesDataStructure() {
      let newDataStruct = {}

      this.mainCategories.forEach((cat) => {
        if (cat.name !== "") {
          let reversedPath = this.reverseCategoryPath(cat)
          if (!newDataStruct[reversedPath.name]) {
            newDataStruct[reversedPath.name] = reversedPath
          } else {
            let oldChildren = [
              ...(newDataStruct[reversedPath.name].children ?? []),
            ]
            reversedPath.children.forEach((child) => {
              let isPresent = newDataStruct[reversedPath.name].children?.find(
                (oldChild) => oldChild.identifier === child.identifier
              )
              if (!isPresent) {
                oldChildren.push(child)
              }
            })
            newDataStruct[reversedPath.name].children = oldChildren
          }
        }
      })
      newDataStruct = Object.values(newDataStruct)
      newDataStruct.forEach((value, index) => {
        this.recommendationsExpanded["rec_" + index] = false
        this.categoriesExpanded["cat_" + index] = false
      })
      this.mainCategories = newDataStruct
    },
    async getCategories() {
      axios
        .get(
          Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.CATEGORIES
        )
        .then((response) => {
          if (response.status === 200) {
            this.mainCategories = response.data
            this.changeCategoriesDataStructure()
          }
        })
    },
    removeDynamicBanner(index) {
      var indexToUse = this.orderedShowcaseItemsList.findIndex(
        (item) => item.value == "dynamic-" + index
      )
      this.orderedShowcaseItemsList.splice(indexToUse, 1)
    },
  },
  mounted() {
    if (!this.showcaseLastSearchesOption)
      this.setValue(
        "showcaseLastSearchesOption",
        this.showcaseLastSearchesOptions[0]
      )
    this.getCategories()
    this.resetShowcaseItemList()
  },
}
</script>

<style src="./LayoutDesignDesktop.less" lang="less" scoped></style>
