<template>
  <div :class="['save-changes',{'with-changes':getHistoryChanges.length>0},{beforeUnload:beforeUnload},{locked:!selectedPlanValue.isActive}]">
    <div class="activation-toggle" v-if="getHistoryChanges.length==0" @click="toBillingInfo">
      <div class="label" v-if="!selectedPlanValue.isActive"> {{ $t("saveChanges.locked")}} </div>
      <div class="label" v-else> {{ getCurrentConfiguration.is_active?$t("saveChanges.published"):$t("saveChanges.notPublished")}} </div>
      <toggle-button class="toggle-button" color="#398ff4" :sync="true" :width="40" :value="getCurrentConfiguration.is_active" @change="activationToggle"/>
    </div>
    <div class="changes-container" v-if="getHistoryChanges.length>0">
      <div class="info"></div>
      <div class="buttons">
        <div class="discard" @click="discardChanges"> <a class="icon"/> </div>
        <div class="update" :class="{'is-active':getCurrentConfiguration.is_active,disabled:disabled }" @click="saveChanges"> {{$t("saveChanges.saveChanges")}} </div>
      </div>
    </div>
    <div class="preview-container">
      <div class="preview" @click="toPreview"> {{$t("saveChanges.seePreview")}} <a class="icon"/> </div>
      <div class="tutorial"> <a class="icon"/> </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from '@/utils/Axios';
import Utils from '@/utils/Utils';
import lodash from 'lodash';

export default { 
  name: 'SaveChanges',
  methods:{
    ...mapActions(['setWebsites','setCurrentConfigurationByField','setCurrentConfiguration','setHistoryChanges','setPreviewConfig']),
    async activationToggle(evt){
      if(this.selectedPlanValue.isActive){
        var restUrl=Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.ACTIVATION;
        if(evt.value)
          await axios.post(restUrl)
          .then(()=>this.setCurrentConfigurationByField({field:'is_active',value:evt.value}))
        else
          await axios.delete(restUrl)
          .then(()=>this.setCurrentConfigurationByField({field:'is_active',value:evt.value}))
        axios.get(Utils.SHOPS)
        .then(response=> this.setWebsites(response.data));
      }
    },
    toBillingInfo(){
      if(!this.selectedPlanValue.isActive) this.$router.push({name:'billing-information'})
    },
    discardChanges(){
      var historyChanges= lodash.cloneDeep(this.getHistoryChanges);
      this.setCurrentConfiguration(lodash.clone(historyChanges[historyChanges.length-1]));
      historyChanges.pop();
      this.setHistoryChanges(historyChanges);
      this.$root.$emit('discard-changes');
    },
    async saveChanges(){
      var objectToSend={};
      var objectKey='';
      if(this.$route.matched[0].name=='setup') objectKey='setup';
      else if(this.$route.matched[0].name=='fineTuning') objectKey='fineTuning';
      else if(this.$route.matched[0].name=='layoutDesign') objectKey='layout';
      objectToSend[objectKey]=lodash.cloneDeep(this.getCurrentConfiguration[objectKey]);
      if(objectKey=='setup') {
        if(objectToSend.setup.language_iso){
          await axios.put(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.LANG,{"iso6391": objectToSend.setup.language_iso})
          this.$bvToast.toast('Custom import code changed!', {
            title: '',
            autoHideDelay: 2000,
            solid:true,
            variant:'warning'
          })
          delete objectToSend.setup.language_iso
        }
        if(objectToSend.setup.feedUrl){
          var subObjectToSend=lodash.cloneDeep(this.getCurrentConfiguration.cms_data);
          subObjectToSend.feedUrl=objectToSend.setup.feedUrl;
          await axios.put(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.DATA,subObjectToSend)
          delete objectToSend.setup.feedUrl
        }
      }
      await axios.put(Utils.SHOPS+'/'+this.getCurrentConfiguration.id+Utils.CONF,objectToSend)
      .then(response=> {
        this.setWebsites(response.data);
        // this.setCurrentConfiguration(lodash.clone(Utils.getConfigurationFromWebsite(response.data,this.getCurrentConfiguration)));
      });
      this.setHistoryChanges([]);
      setTimeout(()=>this.$root.$emit('check-indexing'),1000);
    },
    toPreview(){  
      var windowReference = window.open();
      Utils.fromConfigToConfig(this['getCurrentConfiguration'])
      .then(config=>{
        localStorage.setItem('config', JSON.stringify(config));
        let routeData = this.$router.resolve({name: 'preview'});
        windowReference.location=routeData.href; 
      })
    },
    beforeWindowUnload(e){
      e.preventDefault();
      return e.returnValue = '';
    },
  },
  computed:{
    ...mapGetters(['getDisplayedWebsites','getCurrentConfiguration','getHistoryChanges','getSelectedPlan']),
    disabled(){
      return this.getHistoryChanges.length==0?false:Utils.checkChanges(this.getDisplayedWebsites,this.getCurrentConfiguration);
    },
    beforeUnload(){
      if(this.getHistoryChanges.length>0)
        window.addEventListener('beforeunload', this.beforeWindowUnload);
      else
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
      return this.getHistoryChanges.length>0
    },
    selectedPlanValue(){ return Object.values(this.getSelectedPlan)[0]?Object.values(this.getSelectedPlan)[0]:{} },
  },
  mounted(){
    this.$root.$on('save-changes',this.saveChanges);
  }
}
</script>

<style src="./SaveChanges.less" lang="less" scoped></style>