<template>
  <div class="preview">
    <div class="logo"/>
    <div class="focus-arrow"/>
    <div class="text">
      <div class="label1"> {{$t('preview.AS')}} </div>
      <div class="label2"> {{$t('preview.preview')}}</div>
      <div class="label3"> {{$t('preview.try')}} </div>
    </div>
    <input id="search" placeholder="Search for a product" autocomplete="off"/>
    <div class="search-icon"></div>
    <!-- <div id="ittweb-accelasearch-bar-container-container"></div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  data(){
    return{
      readyScripts:[],
      layerurl:""
    }
  },
  computed:{
    ...mapGetters(['getPreviewConfig'])
  },
  methods:{
    addExternalScript(name){
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', name)
      externalScript.onload = () => this.onScriptLoad(name)
      document.body.appendChild(externalScript)
    },
    onScriptLoad(name) {
      this.readyScripts.push(name)
      if (this.readyScripts.length === 3) window.initLayer()
    },
    addCss(url) {
      var link = document.createElement('link')
      link.href = url;
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.media = 'screen'
      document.head.appendChild(link)
      return this
    }
  },
  async mounted(){
    await axios.get('/config.json')
    .then((response)=>{ this.layerurl=response.data.layerurl });
    document.getElementById("favicon").href="/img/as-layer-logo.svg";
    window.AS_CONFIG_DATA=JSON.parse(localStorage.getItem('config'));
    this.addExternalScript(this.layerurl+'/static/js/app.js');
    this.addExternalScript(this.layerurl+'/static/js/main.js');
    this.addExternalScript(this.layerurl+'/static/js/runtime-main.js');
    this.addCss(this.layerurl+'/static/css/app.css');
    this.addCss(this.layerurl+'/static/css/main.css');
  }
}
</script>

<style src="./Preview.less" lang="less" scoped></style> 